.snackbar {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  bottom: 15px;
  left: 15px;
  margin: 10px;
  padding: 15px;
  border-radius: 5px; 
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2),
    0px 2px 2px 0px rgba(0,0,0,0.14),
    0px 3px 1px -2px rgba(0,0,0,0.12);
  justify-content: flex-start;
  align-items: center;
  font-size: .875rem;

  .snackbar-content {
    display: flex;
    align-items: center;
    align-content: space-between;
    justify-content: space-between;
    width: 100%;
  }

  
  .snackbar-message, .snackbar-action {
    padding-right: 20px;
  }

  .snackbar-dismiss {
    display: flex;
    svg {
      width: 1rem;
      padding: 10px;
      &:hover {
        background-color: lightgray;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}

// On mobile devices, span the entire width of the bottom of the screen
@media (max-width: 40rem) {
  .snackbar {

    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    border-radius: 0px;
    .snackbar-message {

    }
  }
}