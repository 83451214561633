/* RGB */
@import url(./utilities.css);
@import url(./normalize.css);
html {
  --danger-red: #de3c4b;
  --callbackpink: #e10079;
  --lightblue: #006fcf;
  --lightblue-hover: #1068a5;
  --evalblack: #303f62;
  --light-orange: #d9534f;
  --dark-orange: #d94f5c;
  --light-gray: #929292;
  --light-yellow: #ffcc33;
  --success-green: #77e371;
  --x-white: #ffffff;
  --x-black: black;
  --x-beige: #f3ecda;
  --x-salmon-100: #ffd2bb;
  --x-salmon-200: #fb8a8b;
  --x-pink: #e10079;
  --x-slateblack-100: #7b889d;
  --x-slateblack-700: #303f62; }

.beat-dot {
  display: inline-block;
  background-color: var(--loaderColor);
  width: 20px;
  height: 20px;
  margin: 2px;
  border-radius: 100%;
  animation: beat 0.7s 0s infinite linear;
  animation-fill-mode: both; }
  .beat-dot.middle {
    animation: beat 0.7s 0.35s infinite linear; }

.navbar-container {
  display: grid; }

.navbar-sticky-row-unathenticated {
  display: grid;
  grid-template-columns: auto;
  grid-template-columns: 100px 1fr 100px;
  grid-template-areas: "dark-mode logo menu ";
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px; }

.navbar-sticky-row-authenticated {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 100px 1fr 100px;
  grid-template-areas: "dark-mode menu button";
  align-items: center; }
  @media (max-width: 40rem) {
    .navbar-sticky-row-authenticated {
      grid-template-rows: 5rem 1fr;
      grid-template-columns: 100px 1fr 100px;
      grid-template-areas: "dark-mode . button" "menu menu menu"; } }

.mavbar-dark-mode-container {
  padding-left: 1rem;
  grid-area: dark-mode; }

.menu {
  justify-self: center; }

.button {
  grid-area: button;
  justify-self: center;
  padding-right: 1rem; }

.menu {
  line-height: 1;
  grid-area: menu; }

.menu__list {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: center; }

.menu__item {
  display: block;
  margin: 1em 0; }

.menu__link {
  display: block;
  padding: .5em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.menu__link:hover,
.menu__link:focus {
  outline: none; }

/* Sebastian */
.menu--sebastian .menu__link {
  position: relative;
  display: block;
  margin: 0 1em;
  text-align: center;
  color: var(--navLinkInactiveColor);
  -webkit-transition: color 0.4s;
  transition: color 0.4s; }

.menu--sebastian .menu__link:hover,
.menu--sebastian .menu__link:focus {
  color: var(--navLinkHoverColor); }

.menu--sebastian .menu__item--current .menu__link {
  color: var(--navLinkActiveColor); }

.menu--sebastian .menu__link::before,
.menu--sebastian .menu__link::after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--light-orange);
  -webkit-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  -webkit-transition: -webkit-transform 0.2s;
  transition: transform 0.2s; }

.menu--sebastian .menu__link::before {
  top: 0;
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%; }

.menu--sebastian .menu__link::after {
  bottom: 0;
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%; }

.menu--sebastian .menu__item--current .menu__link::before,
.menu--sebastian .menu__item--current .menu__link::after {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s; }

.menu-inner {
  max-width: 100%;
  z-index: 1100;
  height: 48px;
  line-height: 48px;
  margin: auto;
  margin-bottom: auto;
  margin-bottom: -1px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  line-height: initial;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid var(--accents-2); }
  .menu-inner a {
    position: relative;
    display: inline-block;
    padding: 12px;
    color: #666666;
    -webkit-text-decoration: none;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease; }
    .menu-inner a:hover {
      color: var(--app-foreground);
      text-decoration: none; }
    .menu-inner a:first-of-type {
      margin-left: -9px; }
    .menu-inner a.active {
      color: var(--app-foreground); }
      .menu-inner a.active::before {
        content: '';
        display: block;
        position: absolute;
        height: 0;
        left: 9px;
        right: 9px;
        bottom: 0;
        border-bottom: 2px solid;
        color: var(--app-foreground); }

.login-page {
  display: grid;
  min-height: 100vh;
  padding: 0;
  grid-template-areas: 'dark dark' 'left right';
  grid-template-rows: 80px 1fr; }
  @media (max-width: 40rem) {
    .login-page {
      grid-template-rows: 50px auto 1fr;
      grid-template-areas: 'dark' 'left' 'right'; }
      .login-page .right .menu--sebastian .menu__link {
        margin: 0 0.5em; } }
  .login-page .login-dark-mode-container {
    grid-area: dark;
    display: flex;
    align-items: center;
    justify-content: center; }
  .login-page .left-container {
    display: grid;
    grid-area: left;
    background-color: var(--app-background);
    align-items: center;
    justify-items: center; }
    .login-page .left-container img {
      max-width: 50%; }
  .login-page .right-container {
    display: grid;
    grid-area: right;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 80px;
    grid-template-areas: 'page' 'menu';
    align-items: center;
    justify-items: center;
    margin-right: 20px; }
    .login-page .right-container .page {
      height: 100%;
      width: 100%; }
      .login-page .right-container .page .flex-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* Vertical center alignment */
        justify-content: center;
        /* Horizontal center alignment */
        width: 100%;
        height: 100%; }

@media (max-width: 40rem) {
  .login-page .right-container {
    margin-right: 0; } }

@keyframes slideInFromBottom {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translateY(0); } }

.image-container {
  grid-area: imagearea;
  text-align: center; }
  .image-container img {
    max-width: 80%; }

.text-container {
  color: var(--app-foreground); }

@media (max-width: 700px) {
  .hero-container {
    display: grid;
    grid-template-areas: 'imagearea' 'textarea'; }
  .text-container {
    text-align: center; } }

@media (min-width: 700px) {
  .hero-container {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-areas: 'imagearea textarea'; } }

.products {
  display: grid;
  grid-gap: 5em;
  grid-template-columns: repeat(auto-fit, 230px);
  justify-content: center; }
  @media (min-width: 960px) {
    .products {
      grid-gap: 10em; } }
  .products .product-logo {
    text-align: center;
    width: 100%;
    height: 200px;
    padding-bottom: 10px; }
    .products .product-logo img {
      max-width: 100%;
      max-height: 100%; }
  .products .product {
    display: grid;
    grid-template-areas: 'product-logo' 'product-link' 'product-point' 'product-seconday-action' 'product-primary-action'; }
    .products .product h2 {
      text-align: center;
      font-size: 1.5em;
      font-weight: 400;
      margin: 0;
      padding: 0.5em 0; }
      .products .product h2 a {
        text-decoration: none;
        color: var(--tileTitleColor); }
    .products .product p {
      color: var(--app-foreground);
      text-align: center; }

.projects {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 150px));
  justify-content: center;
  padding-bottom: 10px;
  backface-visibility: hidden; }
  .projects a {
    text-decoration: none;
    text-align: center;
    border-bottom: 1px solid var(--timesheetMenuTileBorderColor);
    padding: 2rem;
    color: var(--app-foreground);
    transition: all 0.2s ease-in-out; }
    .projects a p {
      color: var(--tileTitleColor); }
    .projects a img {
      max-width: 100%;
      padding-bottom: 10px;
      filter: invert(50%); }
    .projects a:hover {
      transform: scale(1.1);
      border-bottom-color: var(--timesheetMenuTileBorderColorInverse); }

@media (min-width: 40rem) and (max-width: 60rem) {
  .projects {
    grid-template-columns: repeat(auto-fit, minmax(50px, 100px));
    grid-gap: 3em; }
    .projects a {
      padding: 1rem; } }

@media (max-width: 40rem) {
  .projects {
    grid-template-columns: repeat(auto-fit, minmax(50px, 100px));
    grid-gap: 2em; }
    .projects a {
      padding: 1rem; } }

.contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .contact .profile-card {
    margin-right: 3rem;
    padding: 10px;
    width: 25%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    vertical-align: top;
    border-bottom: 1px solid var(--timesheetMenuTileBorderColor); }
    .contact .profile-card:hover {
      border-bottom: 1px solid var(--timesheetMenuTileBorderColorInverse); }
    .contact .profile-card .card-header {
      padding: 10px; }
    .contact .profile-card .card-content {
      flex: 1 1 auto;
      padding: 10px; }
    .contact .profile-card .card-footer {
      padding: 10px; }

.notification {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1vw 0;
  box-shadow: 0px 1px 5px 0px var(--app-foreground), 0px 2px 2px 0px var(--app-foreground), 0px 3px 1px -2px var(--app-foreground); }
  .notification::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%; }
  .notification.info::before {
    background-color: var(--lightblue); }
  .notification.warning::before {
    background-color: var(--light-yellow); }
  .notification.danger::before {
    background-color: var(--danger-red); }
  .notification.success::before {
    background-color: var(--success-green); }
  .notification div {
    padding-right: 20px; }
  .notification .notification-icon {
    padding-left: 20px; }
    .notification .notification-icon svg {
      width: 2rem; }
    .notification .notification-icon.success svg {
      stroke: var(--success-green);
      color: var(--success-green); }
  .notification .notification-close {
    display: flex; }
    .notification .notification-close svg {
      width: 1rem;
      padding: 10px; }
      .notification .notification-close svg:hover {
        background-color: lightgray;
        border-radius: 5px;
        cursor: pointer; }
  .notification .notification-action a {
    color: var(--app-foreground);
    text-decoration: underline; }

body.dark-mode .notification.info::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: var(--x-white); }

body.dark-mode .notification .notification-icon svg,
body.dark-mode .notification .notification-close svg {
  fill: white;
  fill-opacity: 0.9; }
  body.dark-mode .notification .notification-icon svg:hover,
  body.dark-mode .notification .notification-close svg:hover {
    fill: black; }

@media screen and (max-width: 40rem) {
  .notification {
    flex-wrap: wrap; }
    .notification .notification-icon,
    .notification .notification-message,
    .notification .notification-action {
      padding-bottom: 10px;
      padding-left: 8px;
      width: 100%;
      text-align: center;
      align-content: center;
      justify-content: center; } }

.snackbar {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  bottom: 15px;
  left: 15px;
  margin: 10px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  justify-content: flex-start;
  align-items: center;
  font-size: .875rem; }
  .snackbar .snackbar-content {
    display: flex;
    align-items: center;
    align-content: space-between;
    justify-content: space-between;
    width: 100%; }
  .snackbar .snackbar-message, .snackbar .snackbar-action {
    padding-right: 20px; }
  .snackbar .snackbar-dismiss {
    display: flex; }
    .snackbar .snackbar-dismiss svg {
      width: 1rem;
      padding: 10px; }
      .snackbar .snackbar-dismiss svg:hover {
        background-color: lightgray;
        border-radius: 5px;
        cursor: pointer; }

@media (max-width: 40rem) {
  .snackbar {
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    border-radius: 0px; } }

@-webkit-keyframes pulse_animation {
  0% {
    transform: scale(1); }
  30% {
    transform: scale(1); }
  40% {
    transform: scale(1.08); }
  50% {
    transform: scale(1); }
  60% {
    transform: scale(1); }
  70% {
    transform: scale(1.05); }
  80% {
    transform: scale(1); }
  100% {
    transform: scale(1); } }

@-moz-keyframes pulse_animation {
  0% {
    transform: scale(1); }
  30% {
    transform: scale(1); }
  40% {
    transform: scale(1.08); }
  50% {
    transform: scale(1); }
  60% {
    transform: scale(1); }
  70% {
    transform: scale(1.05); }
  80% {
    transform: scale(1); }
  100% {
    transform: scale(1); } }

@-ms-keyframes pulse_animation {
  0% {
    transform: scale(1); }
  30% {
    transform: scale(1); }
  40% {
    transform: scale(1.08); }
  50% {
    transform: scale(1); }
  60% {
    transform: scale(1); }
  70% {
    transform: scale(1.05); }
  80% {
    transform: scale(1); }
  100% {
    transform: scale(1); } }

@-o-keyframes pulse_animation {
  0% {
    transform: scale(1); }
  30% {
    transform: scale(1); }
  40% {
    transform: scale(1.08); }
  50% {
    transform: scale(1); }
  60% {
    transform: scale(1); }
  70% {
    transform: scale(1.05); }
  80% {
    transform: scale(1); }
  100% {
    transform: scale(1); } }

@keyframes pulse_animation {
  0% {
    transform: scale(1); }
  30% {
    transform: scale(1); }
  40% {
    transform: scale(1.08); }
  50% {
    transform: scale(1); }
  60% {
    transform: scale(1); }
  70% {
    transform: scale(1.05); }
  80% {
    transform: scale(1); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes beat {
  50% {
    transform: scale(0.75);
    opacity: 0.2; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-moz-keyframes beat {
  50% {
    transform: scale(0.75);
    opacity: 0.2; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-ms-keyframes beat {
  50% {
    transform: scale(0.75);
    opacity: 0.2; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-o-keyframes beat {
  50% {
    transform: scale(0.75);
    opacity: 0.2; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes beat {
  50% {
    transform: scale(0.75);
    opacity: 0.2; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes spinner {
  to {
    transform: rotate(360deg); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.content-container {
  padding-top: 2rem;
  padding-bottom: 3rem;
  display: grid;
  grid-template-columns: 1rem repeat(12, minmax(1rem, 3.75rem)) 1rem;
  grid-gap: 0.25rem;
  background-color: var(--app-background); }
  @media (min-width: 640px) {
    .content-container {
      grid-template-columns: 1fr repeat(12, minmax(1rem, 3.75rem)) 1fr;
      grid-gap: 1.25rem; } }

.content {
  grid-column: 3 / span 10; }
  @media (max-width: 40rem) {
    .content {
      grid-column: 4 / span 8; } }
  .content a,
  .content h2 {
    color: #ff9dbc; }
  .content a {
    text-decoration: underline; }
  .content h2 {
    margin: 2rem 0 0.8em;
    padding-bottom: 0.7em;
    border-bottom: 1px solid black; }
  .content h3 {
    padding-left: 0.5rem;
    border-left: 2px solid var(--light-orange); }
  .content ul,
  .content ol {
    line-height: 1.6em; }

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */ }

body {
  --warningButtonBackgroundColor: var(--light-yellow);
  --warningButtonHoverBackgroundColor: var(--x-white);
  --warningButtonTextColor: var(--x-white);
  --warningButtonHoverTextColor: var(--x-black);
  --warningButtonBorderColor: var(--light-yellow);
  --warningButtonHoverBorderColor: var(--light-yellow);
  --dangerButtonTextColor: var(--x-white);
  --outlinedButtonBackgroundColor: var(--app-background);
  --outlinedButtonTextColor: #666;
  --warningOutlinedButtonBorderColor: var(--light-yellow);
  --dangerOutlinedButtonBorderColor: var(--danger-red); }

body.light-mode {
  --evalblackButtonBackgroundColor: var(--evalblack);
  --evalblackButtonHoverBackgroundColor: var(--x-white);
  --evalblackButtonTextColor: var(--x-white);
  --evalblackButtonHoverTextColor: var(--evalblack);
  --evalblackButtonBorderColor: var(--evalblack);
  --evalblackButtonHoverBorderColor: var(--evalblack);
  --outlinedButtonHoverTextColor: black;
  --outlinedButtonBorderColor: #eaeaea; }

body.dark-mode {
  --evalblackButtonBackgroundColor: white;
  --evalblackButtonHoverBackgroundColor: var(--evalblack);
  --evalblackButtonTextColor: var(--x-black);
  --evalblackButtonHoverTextColor: white;
  --evalblackButtonBorderColor: white;
  --evalblackButtonHoverBorderColor: white;
  --warningButtonHoverTextColor: var(--light-yellow);
  --dangerButtonHoverTextColor: var(--danger-red);
  --outlinedButtonHoverTextColor: white;
  --outlinedButtonBorderColor: white; }

[class^='btn'] {
  --buttonBackgroundColor: var(--evalblackButtonBackgroundColor);
  --buttonHoverBackgroundColor: var(--evalblackButtonHoverBackgroundColor);
  --buttonTextColor: var(--evalblackButtonTextColor);
  --buttonHoverTextColor: var(--evalblackButtonHoverTextColor);
  --buttonBorderColor: var(--evalblackButtonBorderColor);
  --buttonHoverBorderColor: var(--evalblackButtonHoverBorderColor);
  word-wrap: break-word;
  text-align: center;
  align-items: flex-start;
  box-sizing: border-box;
  margin: 0em;
  border-image: initial;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-text-decoration: none;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  position: relative;
  vertical-align: middle;
  text-transform: uppercase;
  line-height: 38px;
  min-width: 200px;
  height: 40px;
  font-weight: 500;
  font-size: 12px;
  flex-shrink: 0;
  color: var(--buttonTextColor);
  background-color: var(--buttonBackgroundColor);
  border: 1px solid var(--buttonBorderColor);
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  padding: 0px 25px;
  border-radius: 5px;
  transition: all 0.2s ease 0s;
  overflow: hidden;
  outline: none; }
  [class^='btn'][class*='outlined'] {
    --buttonBackgroundColor: var(--outlinedButtonBackgroundColor);
    --buttonHoverBackgroundColor: var(--outlinedButtonHoverBackgroundColor);
    --buttonTextColor: var(--outlinedButtonTextColor);
    --buttonHoverTextColor: var(--outlinedButtonHoverTextColor);
    --buttonBorderColor: #eaeaea;
    --buttonHoverBorderColor: var(--evalblack); }
  [class^='btn'][class*='warning'] {
    --buttonBackgroundColor: var(--warningButtonBackgroundColor);
    --buttonHoverBackgroundColor: var(--warningButtonHoverBackgroundColor);
    --buttonTextColor: var(--warningButtonTextColor);
    --buttonHoverTextColor: var(--warningButtonHoverTextColor);
    --buttonBorderColor: var(--warningButtonBorderColor);
    --buttonHoverBorderColor: var(--warningButtonHoverBorderColor); }
    [class^='btn'][class*='warning'][class*='outlined'] {
      --buttonTextColor: var(--outlinedButtonTextColor);
      --buttonHoverTextColor: var(--outlinedButtonHoverTextColor);
      --buttonHoverBorderColor: black; }
  [class^='btn'][class*='danger'] {
    --buttonBackgroundColor: var(--danger-red);
    --buttonHoverBackgroundColor: var(--x-white);
    --buttonTextColor: var(--x-white);
    --buttonHoverTextColor: var(--dangerButtonHoverTextColor);
    --buttonBorderColor: var(--danger-red);
    --buttonHoverBorderColor: var(--danger-red); }
    [class^='btn'][class*='danger'][class*='outlined'] {
      --buttonTextColor: var(--outlinedButtonTextColor);
      --buttonHoverTextColor: var(--outlinedButtonHoverTextColor);
      --buttonHoverBorderColor: black; }
  [class^='btn']:hover {
    background-color: transparent;
    color: var(--buttonHoverTextColor); }
  [class^='btn'][class*='outlined'] {
    padding: 0 25px 0 25px;
    color: var(--buttonTextColor);
    background-color: var(--outlinedButtonBackgroundColor);
    border: 1px solid var(--buttonBorderColor);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease; }
    [class^='btn'][class*='outlined']:hover {
      color: var(--buttonHoverTextColor);
      border: 1px solid var(--buttonHoverBorderColor); }

.btn--disabled {
  background: var(--accents-1) !important;
  border-color: var(--accents-2) !important;
  color: var(--accents-4) !important;
  cursor: not-allowed !important;
  filter: grayscale(1) !important; }
  .btn--disabled:hover {
    background: var(--accents-1) !important;
    border-color: var(--accents-2) !important;
    color: var(--accents-4) !important;
    cursor: not-allowed !important;
    filter: grayscale(1) !important; }

.btn--sm {
  min-width: fit-content;
  height: 30px !important;
  line-height: 22px !important;
  padding: 0 10px !important; }

.button-loader-container .loader-text {
  text-align: center;
  padding-top: 0.5rem; }
  .button-loader-container .loader-text:hover {
    opacity: 0.5;
    transition: opacity 0.3s ease;
    cursor: pointer; }
  .button-loader-container .loader-text.succeeded {
    color: #0acda2; }
  .button-loader-container .loader-text.failed {
    color: #cd0a58; }

.circle-loader {
  text-align: center;
  margin-top: -0.8em;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left-color: var(--evalblackButtonBackgroundColor);
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 4em;
  height: 4em; }

.load-complete {
  -webkit-animation: none;
  animation: none;
  transition: border 500ms ease-out; }

.load-complete.checkmark {
  border-color: #0acda2; }

.load-complete.failmark {
  border-color: #cd0a58; }

.load-complete.failmark:hover,
.load-complete.checkmark:hover {
  opacity: 0.5;
  transition: opacity 0.3s ease;
  cursor: pointer; }

.failmark > div.draw {
  display: block; }

.failmark > div.draw:before {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: failmark;
  transform: scaleX(-1) rotate(135deg); }

.failmark > div.draw:before {
  opacity: 1;
  height: 1em;
  width: 1em;
  transform-origin: left top;
  border-right: 3px solid #cd0a58;
  border-top: 3px solid #cd0a58;
  content: '';
  left: 1.2em;
  top: 1.3em;
  position: absolute; }

.failmark > div.draw:after {
  opacity: 1;
  height: 1em;
  width: 1em;
  transform-origin: left top;
  border-right: 3px solid #cd0a58;
  border-top: 3px solid #cd0a58;
  content: '';
  left: 2.85em;
  top: 2.75em;
  position: absolute;
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: failmark;
  transform: scaleX(-1) rotate(314deg); }

.checkmark > div.draw {
  display: block; }

.checkmark > div.draw:after {
  opacity: 1;
  height: 1.75em;
  width: 0.875em;
  transform-origin: left top;
  border-right: 3px solid #0acda2;
  border-top: 3px solid #0acda2;
  content: '';
  left: 1em;
  top: 2em;
  position: absolute;
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg); }
  .checkmark > div.draw:after:hover {
    opacity: 0.5; }

.svg-container-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  outline: none; }
  .svg-container-button.is-open svg {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg); }

@keyframes loader-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes failmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1; }
  20% {
    height: 0;
    width: 1em;
    opacity: 1; }
  40% {
    height: 1em;
    width: 1em;
    opacity: 1; }
  100% {
    height: 1em;
    width: 1em;
    opacity: 1; } }

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1; }
  20% {
    height: 0;
    width: 0.875em;
    opacity: 1; }
  40% {
    height: 1.75em;
    width: 0.875em;
    opacity: 1; }
  100% {
    height: 1.75em;
    width: 0.875em;
    opacity: 1; } }

.toggle-wrapper {
  -webkit-align-items: middle;
  -webkit-box-align: middle;
  -ms-flex-align: middle;
  align-items: middle;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 3px 0;
  position: relative;
  vertical-align: middle;
  white-space: nowrap; }
  .toggle-wrapper input {
    height: 0;
    opacity: 0;
    overflow: hidden;
    width: 0;
    margin: 0;
    display: none; }
  .toggle-wrapper label {
    margin-left: 10px;
    cursor: pointer;
    color: #666; }
    .toggle-wrapper label.disabled {
      cursor: not-allowed; }

.toggle-span {
  border-radius: 14px;
  cursor: pointer;
  display: inline-block;
  height: 14px;
  position: relative;
  -webkit-transition-delay: 0.12s;
  transition-delay: 0.12s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: background, border;
  transition-property: background, border;
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  width: 28px; }
  .toggle-span:not(.checked) {
    background: #cccccc;
    border: 1px solid #cccccc; }
  .toggle-span.checked {
    background: #067df7;
    border: 1px solid #067df7; }
  .toggle-span.disabled {
    cursor: not-allowed; }

.toggle-thumb {
  border-radius: 50%;
  border: 1px solid transparent;
  cursor: pointer;
  height: 12px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-transition-duration: 0.28s;
  transition-duration: 0.28s;
  -webkit-transition-property: left;
  transition-property: left;
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  width: 12px; }
  .toggle-thumb:not(.checked) {
    background: var(--app-background);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    left: 0; }
  .toggle-thumb.checked {
    background: var(--app-background);
    left: calc(28px - 12px - 2px); }
  .toggle-thumb.disabled {
    cursor: not-allowed; }

.checkbox-container > input {
  display: none; }

.check {
  cursor: pointer;
  position: relative;
  margin: auto;
  width: 18px;
  height: 18px;
  margin: auto;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0); }

.check:before {
  content: '';
  position: absolute;
  top: -15px;
  left: -15px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--accents-1);
  opacity: 0;
  transition: opacity 0.2s ease; }

.check svg {
  position: relative;
  z-index: 1;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: var(--app-foreground);
  stroke-width: 1.5;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease; }

.check svg path {
  stroke-dasharray: 60;
  stroke-dashoffset: 0;
  transition: all 0.3s linear; }

.check svg polyline {
  stroke-dasharray: 22;
  stroke-dashoffset: 66;
  transition: all 0.2s linear; }

.check:hover:before {
  opacity: 1; }

.check:hover svg {
  stroke: #3368bf; }

.checkbox-container > input:checked + .check svg path {
  stroke-dashoffset: 60;
  transition: all 0.3s linear; }

.checkbox-container > input:checked + .check svg polyline {
  stroke-dashoffset: 42;
  transition: all 0.2s linear;
  transition-delay: 0.15s; }

.outer-radio-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  line-height: 24px;
  position: relative; }
  .outer-radio-wrapper:first-child {
    margin-top: 20px; }
  .outer-radio-wrapper:not(:first-child) {
    margin-top: 20px; }

.inner-radio-wrapper {
  line-height: 24px; }

.radio-label {
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 30px;
  color: var(--app-foreground);
  line-height: 24px; }
  .radio-label .disabled {
    color: var(--accents-5);
    cursor: not-allowed;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .radio-label .disabled input .checkmark::after {
      background: var(--accents-2); }
  .radio-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    color: white;
    line-height: 24px; }
  .radio-label .label-name {
    font-size: 16px;
    font-weight: 600; }
  .radio-label .label-description {
    font-size: 14px;
    color: var(--accents-3); }
  .radio-label .checkmark {
    position: absolute;
    top: 6px;
    left: 0;
    height: 14px;
    width: 14px;
    border: 1px solid var(--accents-5);
    border-radius: 50%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease; }
    .radio-label .checkmark::after {
      content: '';
      position: absolute;
      -webkit-transform: scale(0);
      -ms-transform: scale(0);
      transform: scale(0);
      top: 0px;
      left: 0px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: var(--app-foreground);
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease; }
    .radio-label .checkmark.checked {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease; }
      .radio-label .checkmark.checked::after {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease; }

.inline-label-component-wrapper {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: initial;
  flex-direction: row; }
  .inline-label-component-wrapper.right {
    flex-direction: row-reverse; }
    .inline-label-component-wrapper.right .label-span {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 5px;
      border-bottom: 1px solid var(--accents-2);
      border-left: 0;
      border-right: 1px solid var(--accents-2);
      border-top-left-radius: 0;
      border-top-right-radius: 5px;
      border-top: 1px solid var(--accents-2); }
    .inline-label-component-wrapper.right .inline-label-input-wrapper {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 0;
      border-top-left-radius: 5px;
      border-top-right-radius: 0; }

.input-outer-wrapper {
  -moz-box-align: center;
  align-items: center;
  border-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid var(--accents-2);
  display: inline-flex;
  height: 37px;
  position: relative;
  transition: border 0.2s ease 0s, color 0.2s ease 0s;
  vertical-align: middle;
  width: initial;
  background: var(--app-background); }
  .input-outer-wrapper.right {
    flex-direction: row-reverse; }
  .input-outer-wrapper:focus-within {
    border: 1px solid var(--accents-5); }
  .input-outer-wrapper.disabled {
    cursor: not-allowed;
    background: var(--accents-1); }
  .input-outer-wrapper.errored {
    border-color: var(--danger-red); }
    .input-outer-wrapper.errored input {
      color: var(--danger-red) !important; }

.input-label {
  display: inline-block;
  font-weight: 600;
  padding: 0 8px 8px 0;
  font-size: 16px;
  line-height: 24px;
  color: #666; }

.input-inner-wrapper {
  display: block;
  position: relative;
  width: 100%;
  margin: 4px 10px; }
  .input-inner-wrapper.with-icon {
    margin-right: 0; }

.icon-span {
  webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 0 10px;
  vertical-align: middle; }

.label-span {
  background-color: var(--app-background);
  border-bottom-left-radius: 5px;
  border-bottom: 1px solid var(--accents-2);
  border-left: 1px solid var(--accents-2);
  border-top-left-radius: 5px;
  border-top: 1px solid var(--accents-2);
  color: #9b9b9b;
  font-size: 14px;
  line-height: 1;
  margin: 0;
  padding: 10px;
  pointer-events: none;
  width: initial; }

.error-span {
  color: var(--danger-red);
  padding: 10px;
  pointer-events: none;
  margin: 0;
  font-size: 14px; }

.inline-label-input-wrapper {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

input[type='text'],
input[type='number'],
input[type='email'],
input[type='password'] {
  border-radius: 0px;
  border: medium none;
  box-shadow: none;
  box-sizing: border-box;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  line-height: 27px;
  outline: currentcolor none 0px;
  width: 100%;
  color: var(--app-foreground);
  background-color: transparent;
  caret-color: var(--app-foreground); }
  input[type='text']::placeholder,
  input[type='number']::placeholder,
  input[type='email']::placeholder,
  input[type='password']::placeholder {
    color: var(--accents-5); }
  input[type='text']:focus,
  input[type='number']:focus,
  input[type='email']:focus,
  input[type='password']:focus {
    outline-offset: -2px; }
  input[type='text']:disabled,
  input[type='number']:disabled,
  input[type='email']:disabled,
  input[type='password']:disabled {
    color: #999999;
    cursor: not-allowed;
    border-radius: 5px; }

.textarea-wrapper {
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  vertical-align: middle;
  border: 1px solid var(--accents-2);
  border-radius: 5px;
  transition: border 0.2s ease 0s, color 0.2s ease 0s;
  margin-bottom: 10px; }
  .textarea-wrapper:focus-within {
    border-color: #888888; }
  .textarea-wrapper.errored textarea {
    color: #ff001f; }

textarea {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: initial;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  -webkit-appearance: textarea;
  -webkit-rtl-ordering: logical;
  flex-direction: column;
  resize: auto;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  margin: 0em;
  font: 400 11px system-ui;
  border-width: 1px;
  border-style: solid;
  border-color: initial;
  border-image: initial;
  padding: 2px;
  background-color: transparent;
  box-shadow: none;
  box-sizing: border-box;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  line-height: 1.7;
  height: 100%;
  min-height: 100px;
  resize: none;
  width: 100%;
  color: var(--app-foreground);
  border-radius: 0px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  padding: 10px;
  caret-color: var(--app-foreground); }
  textarea:disabled {
    background: var(--accents-1);
    cursor: not-allowed;
    border-radius: 5px; }

.collapse {
  border-bottom: 1px solid var(--accents-2);
  width: 100%; }
  .collapse .collapse-title {
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: justify;
    justify-content: space-between;
    min-height: 55px;
    margin: 0px;
    text-transform: none;
    color: var(--app-foreground);
    cursor: pointer;
    outline-width: 0px; }
  .collapse .collapse-content {
    overflow-y: hidden;
    transition: max-height 0.2s ease; }

.stepper {
  display: flex;
  align-items: center; }
  .stepper .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding-right: 20px; }
    .stepper .step:last-of-type {
      padding-left: 20px; }
    .stepper .step svg {
      max-width: 40px;
      padding-bottom: 10px;
      fill: var(--accents-2); }
      .stepper .step svg text {
        fill: var(--accents-5); }
    .stepper .step.active svg {
      fill: var(--lightblue); }
      .stepper .step.active svg text {
        fill: white; }
  .stepper .step-connector {
    flex: 1 1 auto; }
    .stepper .step-connector span {
      display: block;
      border: 1px solid var(--accents-2); }

table {
  max-width: 100%;
  max-height: 100%;
  border-collapse: collapse;
  box-shadow: 0px 1px 5px 0px var(--app-foreground), 0px 2px 2px 0px var(--app-foreground), 0px 3px 1px -2px var(--app-foreground); }
  table td, table th {
    padding: 12px 20px; }
  table thead th {
    text-align: left;
    border-bottom: 3px solid var(--evalblack); }
  table tbody tr td > input {
    width: 2rem; }
  table tbody tr .row-expander-plus, table tbody tr .mobile-total, table tfoot tr .row-expander-plus, table tfoot tr .mobile-total {
    display: none; }

@media (max-width: 60rem) {
  table td, table th {
    padding: 4px 8px; }
    table td input, table th input {
      width: 1.3rem; } }

.timesheet-table thead th {
  border-color: var(--success-green); }

.timesheet-table.too-many-hours thead th, .timesheet-table.too-few-hours thead th {
  border-color: var(--danger-red); }

.timesheet-table tbody tr td .timesheet-input {
  max-width: 4rem; }

table tbody .selectable:hover {
  cursor: pointer; }

table tbody .hoverable:hover {
  background-color: var(--accents-2); }

table tbody .has-error {
  color: var(--danger-red); }

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media (max-width: 46rem) {
  /* Force table to not be like tables anymore */
  .timesheet-table {
    display: block;
    /* Hide table headers (but not display: none;, for accessibility) */
    /*
        Label the data
        */ }
    .timesheet-table thead, .timesheet-table tbody, .timesheet-table tfoot, .timesheet-table th, .timesheet-table td, .timesheet-table tr {
      display: block; }
    .timesheet-table thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px; }
    .timesheet-table tbody tr td:not(:first-child), .timesheet-table tfoot tr td:not(:first-child) {
      display: none; }
    .timesheet-table tbody tr:hover, .timesheet-table tfoot tr:hover {
      color: var(--accents-5); }
    .timesheet-table tbody tr .row-expander-plus, .timesheet-table tbody tr .mobile-total, .timesheet-table tfoot tr .row-expander-plus, .timesheet-table tfoot tr .mobile-total {
      display: inline-block; }
    .timesheet-table tbody tr.mobile-expanded td:nth-of-type(n+2), .timesheet-table tfoot tr.mobile-expanded td:nth-of-type(n+2) {
      display: block;
      -webkit-animation: fadeIn 1s;
      animation: fadeIn 1s; }
    .timesheet-table td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid var(--accents-4);
      position: relative;
      padding-left: 80%; }
    .timesheet-table td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 10px;
      width: 45%;
      height: 100%;
      white-space: nowrap;
      text-align: left; }
    .timesheet-table td:nth-of-type(1) {
      font-weight: bold;
      padding: 10px;
      display: flex;
      -moz-box-align: center;
      align-items: center;
      -moz-box-pack: justify;
      justify-content: space-between;
      font-weight: 600;
      text-transform: none;
      cursor: pointer;
      outline-width: 0px; }
    .timesheet-table td:nth-of-type(2):before {
      content: "Monday"; }
    .timesheet-table td:nth-of-type(3):before {
      content: "Tuesday"; }
    .timesheet-table td:nth-of-type(4):before {
      content: "Wednesday"; }
    .timesheet-table td:nth-of-type(5):before {
      content: "Thursday"; }
    .timesheet-table td:nth-of-type(6):before {
      content: "Friday"; }
    .timesheet-table td:nth-of-type(7):before {
      content: "Saturday"; }
    .timesheet-table td:nth-of-type(8):before {
      content: "Sunday"; }
    .timesheet-table td:nth-of-type(9):before {
      content: "Charged To"; }
    .timesheet-table td:nth-of-type(10):before {
      content: "Total"; } }

.fy-select {
  min-width: 6rem;
  text-overflow: ellipsis; }

.payperiods-select {
  min-width: 10rem;
  text-overflow: ellipsis; }

.my-custom-select.fy-select {
  min-width: 6rem;
  text-overflow: ellipsis; }

.my-custom-select.payperiods-select {
  min-width: 10rem;
  text-overflow: ellipsis; }

body.dark-mode .my-custom-select .select-prefix__control {
  background-color: #181a1b;
  border-color: #3d3d3d;
  outline-color: currentcolor !important; }
  body.dark-mode .my-custom-select .select-prefix__control .select-prefix__single-value {
    color: #d6d3cd !important; }

body.dark-mode .my-custom-select .select-prefix__menu {
  background-color: #181a1b;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 4px 11px; }
  body.dark-mode .my-custom-select .select-prefix__menu .select-prefix__menu-list {
    scrollbar-color: #2a2c2e #1c1e1f; }
    body.dark-mode .my-custom-select .select-prefix__menu .select-prefix__menu-list .select-prefix__option--is-focused {
      background-color: #222425;
      color: inherit; }

.dark-mode-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 38px;
  width: 80px; }

@media (max-width: 950px) {
  .darkmode-toggle {
    margin-left: -20px;
    -webkit-transform: initial;
    -ms-transform: initial;
    transform: initial; } }

.modal {
  position: fixed;
  height: 0vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.25s;
  z-index: 9999;
  overflow: hidden; }
  .modal.open {
    position: fixed;
    width: 100%;
    min-height: 100%; }
    .modal.open > .content-wrapper {
      transform: scale(1); }
  .modal .content-wrapper {
    position: relative;
    display: grid;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    grid-template-rows: 10% 70% 10%;
    grid-gap: 2rem;
    justify-self: center;
    padding: 2.5rem;
    width: 50%;
    background-color: var(--app-background);
    border-radius: 1rem;
    box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.5);
    transform: scale(0);
    transition: transform 0.25s;
    transition-delay: 0.15s;
    will-change: transform; }
    .modal .content-wrapper .modal-header {
      padding-bottom: 10px;
      border-bottom: 1px solid var(--x-salmon-100);
      max-width: 100%; }

@media (max-width: 40rem) {
  .modal .content-wrapper {
    width: unset; } }

.format-toolbar {
  display: flex;
  border-bottom: solid 1px;
  padding: 10px 0;
  margin: 0 0 10px 0; }

.tooltip-icon-button {
  border: 0; }

.editor-menu {
  padding-left: 15px;
  background: gray; }

.editor-menu-svg {
  cursor: pointer;
  padding-right: 10px;
  fill: #ccc; }
  .editor-menu-svg::hover {
    opacity: 0.7; }
  .editor-menu-svg.active {
    fill: black; }

.react-date-picker__button svg g {
  stroke: var(--app-foreground); }

.react-date-picker__button:enabled:hover svg g, .react-date-picker__button:enabled:focus svg g {
  stroke: var(--accents-4) !important; }

html {
  height: 100%;
  -webkit-overflow-scrolling: touch; }

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
  -webkit-transition: ease-in-out 200ms !important;
  -moz-transition: ease-in-out 200ms !important;
  -ms-transition: ease-in-out 200ms !important;
  -o-transition: ease-in-out 200ms !important;
  transition: ease-in-out 200ms !important;
  transition-delay: 0 !important;
  background: var(--app-background);
  color: var(--app-foreground);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.App {
  padding-top: 60px;
  padding: 2rem;
  font-family: "Nunito", sans-serif;
  min-height: 100vh; }

a {
  text-decoration: none; }

h1, h2, h3, h4, h5, p, div {
  color: var(--app-foreground); }

.heading-1 {
  font-size: 3rem; }

.heading-2 {
  font-size: 2.25rem; }

.heading-3 {
  font-size: 1.5rem; }

.heading-4 {
  font-size: 1.25rem; }

.heading-5 {
  font-size: 1rem; }

.heading-6 {
  font-size: .875rem; }

.i {
  font-style: italic; }

.b {
  font-weight: bold; }

.underline {
  text-decoration: underline; }

.strike {
  text-decoration: line-through; }

.ttc {
  text-transform: capitalize; }

.ttu {
  text-transform: uppercase; }

body.light-mode {
  --accents-1: #fafafa;
  --accents-2: #eaeaea;
  --accents-3: #999999;
  --accents-4: #888888;
  --accents-5: #666666;
  --accents-6: #444444;
  --accents-7: #333333;
  --accents-8: #111111;
  --app-background: white;
  --app-foreground: black;
  --loaderColor: var(--evalblack);
  --navLinkActiveColor: var(--light-orange);
  --navLinkInactiveColor: var(--x-black);
  --navLinkHoverColor: darkgrey;
  --tileTitleColor: var(--x-slateblack-700);
  --timesheetMenuTileBorderColor: var(--callbackpink);
  --timesheetMenuTileBorderColorInverse: var(--x-black); }

body.dark-mode {
  --accents-8: #fafafa;
  --accents-7: #eaeaea;
  --accents-6: #999999;
  --accents-5: #888888;
  --accents-4: #666666;
  --accents-3: #444444;
  --accents-2: #333333;
  --accents-1: #111111;
  --app-background: black;
  --app-foreground: #c8c5bf;
  --loaderColor: white;
  --navLinkActiveColor: var(--dark-orange);
  --navLinkInactiveColor: white;
  --navLinkHoverColor: rgba(239, 131, 84, 1);
  --tileTitleColor: var(--x-salmon-100);
  --timesheetMenuTileBorderColor: var(--callbackpink);
  --timesheetMenuTileBorderColorInverse: white; }

.metrics {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr; }
  .metrics .element {
    border: 1px solid white; }

.pulse {
  animation-name: pulse_animation;
  animation-duration: 5000ms;
  transform-origin: 70% 70%;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }
