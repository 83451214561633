.dark-mode-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 38px;
  width: 80px;
}

@media (max-width: 950px) {
  .darkmode-toggle {
    margin-left: -20px;
    -webkit-transform: initial;
    -ms-transform: initial;
    transform: initial;
  }
}
