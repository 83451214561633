.toggle-wrapper {
  -webkit-align-items: middle;
  -webkit-box-align: middle;
  -ms-flex-align: middle;
  align-items: middle;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 3px 0;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;

  input {
    height: 0;
    opacity: 0;
    overflow: hidden;
    width: 0;
    margin: 0;
    display: none;
  }

  label {
    margin-left: 10px;
    cursor: pointer;
    color: #666;
    &.disabled {
      cursor: not-allowed;
    }
  }
}

.toggle-span {
  border-radius: 14px;
  cursor: pointer;
  display: inline-block;
  height: 14px;
  position: relative;
  -webkit-transition-delay: 0.12s;
  transition-delay: 0.12s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: background, border;
  transition-property: background, border;
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  width: 28px;

  &:not(.checked) {
    background: #cccccc;
    border: 1px solid #cccccc;
  }

  &.checked {
    background: #067df7;
    border: 1px solid #067df7;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.toggle-thumb {
  border-radius: 50%;
  border: 1px solid transparent;
  cursor: pointer;
  height: 12px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-transition-duration: 0.28s;
  transition-duration: 0.28s;
  -webkit-transition-property: left;
  transition-property: left;
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  width: 12px;

  &:not(.checked) {
    background: var(--app-background);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    left: 0;
  }

  &.checked {
    background: var(--app-background);
    left: calc(28px - 12px - 2px);
  }

  &.disabled {
    cursor: not-allowed;
  }
}
