
.react-date-picker__button {
  svg g {
      stroke: var(--app-foreground);
      
    }
  &:enabled {
    &:hover,&:focus {
      svg g {
        stroke: var(--accents-4) !important;
      }
    }
  }
}
