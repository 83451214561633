.fy-select {
  min-width: 6rem;
  text-overflow: ellipsis;
}

.payperiods-select {
  min-width: 10rem;
  text-overflow: ellipsis;
}

.my-custom-select {
  &.fy-select {
    min-width: 6rem;
    text-overflow: ellipsis;
  }
  
  &.payperiods-select {
    min-width: 10rem;
    text-overflow: ellipsis;
  }
}

body.dark-mode {
  .my-custom-select {
    .select-prefix__control {
      background-color: rgb(24, 26, 27);
      border-color: rgb(61, 61, 61);
      outline-color: currentcolor !important;
      .select-prefix__single-value {
        color: rgb(214, 211, 205) !important;
      }
    }
    .select-prefix__menu {
      background-color: rgb(24, 26, 27);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 4px 11px;

      .select-prefix__menu-list {
        scrollbar-color: #2a2c2e #1c1e1f;
        .select-prefix__option--is-focused {
          background-color: rgb(34, 36, 37);
          color: inherit;
        }
      }
    }
  }
}
