.collapse {
  border-bottom: 1px solid var(--accents-2);
  width: 100%;
  

  .collapse-title {
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: justify;
    justify-content: space-between;
    min-height: 55px;
    margin: 0px;
    text-transform: none;
    color: var(--app-foreground);
    cursor: pointer;
    outline-width: 0px;
  }

  .collapse-content {
    overflow-y: hidden;
    transition: max-height 0.2s ease;
  }
}

