.outer-radio-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  line-height: 24px;
  position: relative;

  &:first-child {
    margin-top: 20px;
  }

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.inner-radio-wrapper {
  line-height: 24px;
}

.radio-label {
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 30px;
  color: var(--app-foreground);
  line-height: 24px;

  .disabled {
    color: var(--accents-5);
    cursor: not-allowed;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      .checkmark {
        &::after {
          background: var(--accents-2);
        }
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    color: white;
    line-height: 24px;
  }

  .label-name {
    font-size: 16px;
    font-weight: 600;
  }

  .label-description {
    font-size: 14px;
    color: var(--accents-3);
  }

  .checkmark {
    position: absolute;
    top: 6px;
    left: 0;
    height: 14px;
    width: 14px;
    border: 1px solid var(--accents-5);
    border-radius: 50%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;

    &::after {
      content: '';
      position: absolute;
      -webkit-transform: scale(0);
      -ms-transform: scale(0);
      transform: scale(0);
      top: 0px;
      left: 0px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: var(--app-foreground);
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }

    &.checked {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;

      &::after {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
    }
  }
}
