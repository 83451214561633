$body-font: 'Nunito', sans-serif;


$color1: hsla(229%, 19%, 22%, 1);
$color2: hsla(218%, 19%, 38%, 1);
$color3: hsla(180%, 1%, 75%, 1);
$color4: hsla(0%, 0%, 100%, 1);
$color5: hsla(18%, 83%, 63%, 1);

/* RGB */
$color1: rgba(45, 49, 66, 1);
$color2: rgba(79, 93, 117, 1);
$color3: rgba(191, 192, 192, 1);
$color4: rgba(255, 255, 255, 1);
$color5: rgba(239, 131, 84, 1);

html {
  --danger-red: #de3c4b;
  --callbackpink: #e10079;
  --lightblue: #006fcf;
  --lightblue-hover: #1068a5;
  --evalblack: #303f62;
  --light-orange: #d9534f;
  --dark-orange: #d94f5c;
  --light-gray: #929292;
  --light-yellow: #ffcc33;
  --success-green: #77e371;

  --x-white: #ffffff;
  --x-black: black;
  --x-beige: #f3ecda;
  --x-salmon-100: #ffd2bb;
  --x-salmon-200: #fb8a8b;
  --x-pink: #e10079;
  --x-slateblack-100: #7b889d;
  --x-slateblack-700: #303f62;

}
