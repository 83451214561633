.contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .profile-card {
    margin-right: 3rem;
    padding: 10px;
    width: 25%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    vertical-align: top;
    border-bottom: 1px solid var(--timesheetMenuTileBorderColor);

    &:hover {
      border-bottom: 1px solid var(--timesheetMenuTileBorderColorInverse);
    }

    .card-header {
      padding: 10px;
    }

    .card-content {
      flex: 1 1 auto;
      padding: 10px;
    }

    .card-footer {
      padding: 10px;
    }
  }
}