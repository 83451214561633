.beat-dot {
  display: inline-block;
  background-color: var(--loaderColor);
  width: 20px;
  height: 20px;
  margin: 2px;
  border-radius: 100%;
  animation: beat 0.7s 0s infinite linear;
  animation-fill-mode: both;

  &.middle {
    animation: beat 0.7s 0.35s infinite linear;
  }
}