body {
  // Warning
  --warningButtonBackgroundColor: var(--light-yellow);
  --warningButtonHoverBackgroundColor: var(--x-white);
  --warningButtonTextColor: var(--x-white);
  --warningButtonHoverTextColor: var(--x-black);
  --warningButtonBorderColor: var(--light-yellow);
  --warningButtonHoverBorderColor: var(--light-yellow);

  --dangerButtonTextColor: var(--x-white);

  // Outlined
  --outlinedButtonBackgroundColor: var(--app-background);
  --outlinedButtonTextColor: #666;

  // Outlined + Warning
  --warningOutlinedButtonBorderColor: var(--light-yellow);

  // Outlined + Danger
  --dangerOutlinedButtonBorderColor: var(--danger-red);
}

body.light-mode {
  // Primary
  --evalblackButtonBackgroundColor: var(--evalblack);
  --evalblackButtonHoverBackgroundColor: var(--x-white);
  --evalblackButtonTextColor: var(--x-white);
  --evalblackButtonHoverTextColor: var(--evalblack);
  --evalblackButtonBorderColor: var(--evalblack);
  --evalblackButtonHoverBorderColor: var(--evalblack);

  // Outlined
  --outlinedButtonHoverTextColor: black;
  --outlinedButtonBorderColor: #eaeaea;
}

body.dark-mode {
  // Primary
  --evalblackButtonBackgroundColor: white;
  --evalblackButtonHoverBackgroundColor: var(--evalblack);
  --evalblackButtonTextColor: var(--x-black);
  --evalblackButtonHoverTextColor: white;
  --evalblackButtonBorderColor: white;
  --evalblackButtonHoverBorderColor: white;

  --warningButtonHoverTextColor: var(--light-yellow);

  --dangerButtonHoverTextColor: var(--danger-red);

  // Outlined
  --outlinedButtonHoverTextColor: white;
  --outlinedButtonBorderColor: white;
}

[class^='btn'] {
  // This is the default
  --buttonBackgroundColor: var(--evalblackButtonBackgroundColor);
  --buttonHoverBackgroundColor: var(--evalblackButtonHoverBackgroundColor);
  --buttonTextColor: var(--evalblackButtonTextColor);
  --buttonHoverTextColor: var(--evalblackButtonHoverTextColor);
  --buttonBorderColor: var(--evalblackButtonBorderColor);
  --buttonHoverBorderColor: var(--evalblackButtonHoverBorderColor);

  // Default outlined styles
  &[class*='outlined'] {
    --buttonBackgroundColor: var(--outlinedButtonBackgroundColor);
    --buttonHoverBackgroundColor: var(--outlinedButtonHoverBackgroundColor);
    --buttonTextColor: var(--outlinedButtonTextColor);
    --buttonHoverTextColor: var(--outlinedButtonHoverTextColor);
    --buttonBorderColor: #eaeaea;
    --buttonHoverBorderColor: var(--evalblack);
  }

  &[class*='warning'] {
    --buttonBackgroundColor: var(--warningButtonBackgroundColor);
    --buttonHoverBackgroundColor: var(--warningButtonHoverBackgroundColor);
    --buttonTextColor: var(--warningButtonTextColor);
    --buttonHoverTextColor: var(--warningButtonHoverTextColor);
    --buttonBorderColor: var(--warningButtonBorderColor);
    --buttonHoverBorderColor: var(--warningButtonHoverBorderColor);
    &[class*='outlined'] {
      --buttonTextColor: var(--outlinedButtonTextColor);
      --buttonHoverTextColor: var(--outlinedButtonHoverTextColor);
      --buttonHoverBorderColor: black;
    }
  }

  &[class*='danger'] {
    --buttonBackgroundColor: var(--danger-red);
    --buttonHoverBackgroundColor: var(--x-white);
    --buttonTextColor: var(--x-white);
    --buttonHoverTextColor: var(--dangerButtonHoverTextColor);
    --buttonBorderColor: var(--danger-red);
    --buttonHoverBorderColor: var(--danger-red);
    &[class*='outlined'] {
      --buttonTextColor: var(--outlinedButtonTextColor);
      --buttonHoverTextColor: var(--outlinedButtonHoverTextColor);
      --buttonHoverBorderColor: black;
    }
  }

  // Base styles
  // display: inline-block;
  word-wrap: break-word;
  text-align: center;
  align-items: flex-start;
  box-sizing: border-box;
  margin: 0em;
  border-image: initial;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-text-decoration: none;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  position: relative;
  vertical-align: middle;
  text-transform: uppercase;
  line-height: 38px;
  min-width: 200px;
  height: 40px;
  font-weight: 500;
  font-size: 12px;
  flex-shrink: 0;
  color: var(--buttonTextColor);
  background-color: var(--buttonBackgroundColor);
  border: 1px solid var(--buttonBorderColor);
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  padding: 0px 25px;
  border-radius: 5px;
  transition: all 0.2s ease 0s;
  overflow: hidden;
  outline: none;
  &:hover {
    background-color: transparent;
    color: var(--buttonHoverTextColor);
  }

  // Outlined styles
  &[class*='outlined'] {
    padding: 0 25px 0 25px;

    color: var(--buttonTextColor);
    background-color: var(--outlinedButtonBackgroundColor);
    border: 1px solid var(--buttonBorderColor);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    &:hover {
      color: var(--buttonHoverTextColor);
      border: 1px solid var(--buttonHoverBorderColor);
    }
  }
}

.btn--disabled {
  background: var(--accents-1) !important;
  border-color: var(--accents-2) !important;
  color: var(--accents-4) !important;
  cursor: not-allowed !important;
  filter: grayscale(1) !important;

  &:hover {
    background: var(--accents-1) !important;
    border-color: var(--accents-2) !important;
    color: var(--accents-4) !important;
    cursor: not-allowed !important;
    filter: grayscale(1) !important;
  }
}

.btn--sm {
  min-width: fit-content;
  height: 30px !important;
  line-height: 22px !important;
  padding: 0 10px !important;
}

.button-loader-container {
  .loader-text {
    text-align: center;
    padding-top: 0.5rem;
    &:hover {
      opacity: 0.5;
      transition: opacity 0.3s ease;
      cursor: pointer;
    }
    &.succeeded {
      color: #0acda2;
    }

    &.failed {
      color: #cd0a58;
    }
  }
}

.circle-loader {
  text-align: center;
  margin-top: -0.8em;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left-color: var(--evalblackButtonBackgroundColor);
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 4em;
  height: 4em;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  transition: border 500ms ease-out;
}

.load-complete.checkmark {
  border-color: #0acda2;
}

.load-complete.failmark {
  border-color: #cd0a58;
}

.load-complete.failmark,
.load-complete.checkmark {
  &:hover {
    opacity: 0.5;
    transition: opacity 0.3s ease;
    cursor: pointer;
  }
}

.failmark > div.draw {
  display: block;
}
.failmark > div.draw:before {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: failmark;
  transform: scaleX(-1) rotate(135deg);
}
.failmark > div.draw:before {
  opacity: 1;
  height: 1em;
  width: 1em;
  transform-origin: left top;
  border-right: 3px solid #cd0a58;
  border-top: 3px solid #cd0a58;
  content: '';
  left: 1.2em;
  top: 1.3em;
  position: absolute;
}
.failmark > div.draw:after {
  opacity: 1;
  height: 1em;
  width: 1em;
  transform-origin: left top;
  border-right: 3px solid #cd0a58;
  border-top: 3px solid #cd0a58;
  content: '';
  left: 2.85em;
  top: 2.75em;
  position: absolute;
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: failmark;
  transform: scaleX(-1) rotate(314deg);
}

.checkmark > div.draw {
  display: block;
}
.checkmark > div.draw:after {
  opacity: 1;
  height: 1.75em;
  width: 0.875em;
  transform-origin: left top;
  border-right: 3px solid #0acda2;
  border-top: 3px solid #0acda2;
  content: '';
  left: 1em;
  top: 2em;
  position: absolute;
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
  &:hover {
    opacity: 0.5;
  }
}

.svg-container-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  outline: none;
  &.is-open {
    svg {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes failmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 1em;
    opacity: 1;
  }
  40% {
    height: 1em;
    width: 1em;
    opacity: 1;
  }
  100% {
    height: 1em;
    width: 1em;
    opacity: 1;
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 0.875em;
    opacity: 1;
  }
  40% {
    height: 1.75em;
    width: 0.875em;
    opacity: 1;
  }
  100% {
    height: 1.75em;
    width: 0.875em;
    opacity: 1;
  }
}
