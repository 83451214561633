
.navbar-container {
  display: grid;
  // color: var(--tileTitleColor);
  // background-color: var(--navBackgroundColor);
}

.navbar-sticky-row-unathenticated {
	display: grid;
	grid-template-columns: auto;
	grid-template-columns: 100px 1fr 100px;
	grid-template-areas: "dark-mode logo menu ";
	align-items: center;
	padding-bottom: 20px;
	padding-top: 20px;
}

.navbar-sticky-row-authenticated {
	display: grid;
	grid-template-rows: auto;
  grid-template-columns: 100px 1fr 100px;
	grid-template-areas: "dark-mode menu button";
	align-items: center;
	
	@media(max-width: 40rem) {
		grid-template-rows: 5rem 1fr;
		grid-template-columns: 100px 1fr 100px;
		grid-template-areas: 
		"dark-mode . button"
		"menu menu menu";
	}
}

.mavbar-dark-mode-container {
		padding-left: 1rem;
		grid-area: dark-mode;
}
.menu {
	justify-self: center;
}
.button {
	grid-area: button;
	justify-self: center;
	padding-right: 1rem;
}

.menu {
	line-height: 1;
	grid-area: menu;
}

.menu__list {
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	list-style: none;
	justify-content: center;
}

.menu__item {
	display: block;
	margin: 1em 0;
}

.menu__link {
	display: block;
	padding: .5em;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.menu__link:hover,
.menu__link:focus {
	outline: none;
}


/* Sebastian */
.menu--sebastian .menu__link {
	position: relative;
	display: block;
	margin: 0 1em;
	text-align: center;
  // color: #929292;
  color: var(--navLinkInactiveColor);
	-webkit-transition: color 0.4s;
	transition: color 0.4s;
}

.menu--sebastian .menu__link:hover,
.menu--sebastian .menu__link:focus {
  color: var(--navLinkHoverColor);
}

.menu--sebastian .menu__item--current .menu__link {
  color: var(--navLinkActiveColor);
}

.menu--sebastian .menu__link::before,
.menu--sebastian .menu__link::after {
	content: '';
	position: absolute;
	left: 0;
	width: 100%;
	height: 2px;
	background: var(--light-orange);
	-webkit-transform: scale3d(0, 1, 1);
	transform: scale3d(0, 1, 1);
	-webkit-transition: -webkit-transform 0.2s;
	transition: transform 0.2s;
}

.menu--sebastian .menu__link::before {
	top: 0;
	-webkit-transform-origin: 0 50%;
	transform-origin: 0 50%;
}

.menu--sebastian .menu__link::after {
	bottom: 0;
	-webkit-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
}

.menu--sebastian .menu__item--current .menu__link::before,
.menu--sebastian .menu__item--current .menu__link::after {
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
	-webkit-transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
	transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}


// Financial Browse Nabar
.menu-inner {
	max-width: 100%;
	z-index: 1100;
	height: 48px;
	line-height: 48px;
	margin: auto;
	margin-bottom: auto;
	margin-bottom: -1px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: flex-end;
	-webkit-box-align: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	line-height: initial;
	font-size: 16px;
	font-weight: 500;
	border-bottom: 1px solid var(--accents-2);

	a {
		position: relative;
		display: inline-block;
		padding: 12px;
		color: rgb(102, 102, 102);
		-webkit-text-decoration: none;
		text-decoration: none;
		font-size: 14px;
		font-weight: 400;
		-webkit-transition: color 0.2s ease;
		transition: color 0.2s ease;

		&:hover {
			color: var(--app-foreground);
			text-decoration: none;
		}

		&:first-of-type {
			margin-left: -9px;
		}

		&.active {
			color: var(--app-foreground);
			&::before {
				content: '';
				display: block;
				position: absolute;
				height: 0;
				left: 9px;
				right: 9px;
				bottom: 0;
				border-bottom: 2px solid;
				color: var(--app-foreground);
			}
		}
	}
}