.content-container {
  padding-top: 2rem;
  padding-bottom: 3rem;
  display: grid;
  grid-template-columns: 1rem repeat(12, minmax(1rem, 3.75rem)) 1rem;
  grid-gap: 0.25rem;
  @media (min-width: 640px) {
    grid-template-columns: 1fr repeat(12, minmax(1rem, 3.75rem)) 1fr;
    grid-gap: 1.25rem;
  }
  background-color: var(--app-background);
}

// Classes for markdown posts (about, contact, etc)
.content {
  grid-column: 3 / span 10;
  @media (max-width: 40rem) {
    grid-column: 4 / span 8;
  }

  a,
  h2 {
    color: #ff9dbc;
  }
  a {
    text-decoration: underline;
  }
  h2 {
    margin: 2rem 0 0.8em;
    padding-bottom: 0.7em;
    border-bottom: 1px solid black;
  }
  h3 {
    padding-left: 0.5rem;
    border-left: 2px solid var(--light-orange);
  }
  ul,
  ol {
    line-height: 1.6em;
  }
}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}
