.login-page {
  display: grid;
  min-height: 100vh;
  padding: 0;
  grid-template-areas:
    'dark dark'
    'left right';
  grid-template-rows: 80px 1fr;

  @media (max-width: 40rem) {
    grid-template-rows: 50px auto 1fr;
    grid-template-areas:
      'dark'
      'left'
      'right';

    .right {
      .menu--sebastian .menu__link {
        margin: 0 0.5em;
      }
    }
  }

  .login-dark-mode-container {
    grid-area: dark;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .left-container {
    display: grid;
    grid-area: left;
    background-color: var(--app-background);
    align-items: center;
    justify-items: center;
    img {
      max-width: 50%;
    }
  }

  .right-container {
    // Styles for the menu are in navbar.scss
    display: grid;
    grid-area: right;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 80px;
    grid-template-areas:
      'page'
      'menu';
    align-items: center;
    justify-items: center;
    margin-right: 20px;

    .page {
      height: 100%;
      width: 100%;

      .flex-container {
        display: flex;
        flex-direction: column;
        align-items: center; /* Vertical center alignment */
        justify-content: center; /* Horizontal center alignment */
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 40rem) {
  .login-page {
    .right-container {
      margin-right: 0;
    }
  }
  
}
