/* Utility classes */

.border {
  border: 1px solid var(--app-foreground);
}

.border-l {
  border-left: 1px solid var(--app-foreground);
}

.border-r {
  border-right: 1px solid var(--app-foreground);
}

.border-b {
  border-bottom: 1px solid var(--app-foreground);
}

.border-t {
  border-top: 1px solid var(--app-foreground);
}

.border-lr {
  border-left: 1px solid var(--app-foreground);
  border-right: 1px solid var(--app-foreground);
}

.pad {
  padding: 1.25rem;
}

.pad-lr {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.pad-tb {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pad-l {
  padding-left: 1.25rem;
}

.pad-r {
  padding-right: 1.25rem;
}

.pad-t {
  padding-top: 1.25rem;
}

.pad-b {
  padding-bottom: 1.25rem;
}

.pad-0 {
  padding: 0 !important;
}

.pad-0-t {
  padding-top: 0 !important;
}

.pad-0-b {
  padding-bottom: 0 !important;
}

.pad-0-l {
  padding-left: 0 !important;
}

.pad-0-lr,
.pad-0-r {
  padding-right: 0 !important;
}

.pad-0-lr {
  padding-left: 0 !important;
}

.pad-0-tb {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pad-1 {
  padding: 0.625rem !important;
}

.pad-1-t {
  padding-top: 0.625rem !important;
}

.pad-1-b {
  padding-bottom: 0.625rem !important;
}

.pad-1-l {
  padding-left: 0.625rem !important;
}

.pad-1-lr,
.pad-1-r {
  padding-right: 0.625rem !important;
}

.pad-1-lr {
  padding-left: 0.625rem !important;
}

.pad-1-tb {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.pad-2 {
  padding: 1.25rem !important;
}

.pad-2-t {
  padding-top: 1.25rem !important;
}

.pad-2-b {
  padding-bottom: 1.25rem !important;
}

.pad-2-l {
  padding-left: 1.25rem !important;
}

.pad-2-lr,
.pad-2-r {
  padding-right: 1.25rem !important;
}

.pad-2-lr {
  padding-left: 1.25rem !important;
}

.pad-2-tb {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.pad-3 {
  padding: 1.875rem !important;
}

.pad-3-t {
  padding-top: 1.875rem !important;
}

.pad-3-b {
  padding-bottom: 1.875rem !important;
}

.pad-3-l {
  padding-left: 1.875rem !important;
}

.pad-3-lr,
.pad-3-r {
  padding-right: 1.875rem !important;
}

.pad-3-lr {
  padding-left: 1.875rem !important;
}

.pad-3-tb {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.margin {
  margin: 10px;
}

.margin-l {
  margin-left: 10px;
}

.margin-r {
  margin-right: 10px;
}

.margin-t {
  margin-top: 10px;
}

.margin-2-t {
  margin-top: 20px;
}

.margin-3-t {
  margin-top: 30px;
}

.margin-b {
  margin-bottom: 10px;
}

.margin-2-b {
  margin-bottom: 20px;
}

.margin-2-tb {
  margin-top: 20px;
  margin-bottom: 20px;
}

.margin-3-tb {
  margin-top: 30px;
  margin-bottom: 30px;
}

.margin-3-b {
  margin-bottom: 30px;
}

.margin-5-t {
  margin-top: 50px;
}

.margin-5-b {
  margin-bottom: 50px;
}

.margin-rb {
  margin-right: 10px;
  margin-bottom: 10px;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-underline {
  text-decoration: underline;
}

.grid {
  display: grid;
  grid-gap: 1rem;
}

.grid-gap {
  grid-gap: 1rem;
}

.grid-align-center {
  align-items: center;
}

.grid-justify-center {
  justify-content: center;
}

.grid-one-col-select {
  grid-template-columns: minmax(10rem, 20rem);
}

.grid-two-col {
  grid-template-columns: 1fr 1fr;
}

.grid-fy-and-payperiod-select {
  grid-template-columns: minmax(5rem, 10rem) minmax(10rem, 20rem);
}

.grid-two-col-filtered-timesheets {
  grid-template-columns: repeat(auto-fit, minmax(50rem, 1fr));
}
/* Two col layout for filter switched 
Third col is included just in case
*/
.grid-filter-two-col {
  grid-template-columns: 12rem 10rem 10rem;
}

.grid-button-list {
  grid-auto-flow: column;
  /* grid-auto-columns: min-content; */
}

.grid-select-and-button {
  grid-template-columns: minmax(10rem, 28rem) auto auto;
}

.grid-filter-four-col {
  grid-template-columns: repeat(auto-fit, minmax(10rem, 12rem));
  align-items: center;
  justify-content: center;
}

.grid-eight-col {
  grid-template-columns: repeat(auto-fit, minmax(5rem, 10rem));
  grid-gap: 0.5rem;
}

.grid-three-col-projects {
  grid-template-columns: minmax(5rem, 10rem) minmax(5rem, 15rem) 1fr;
}

.grid-three-col-edit-user {
  grid-template-columns: 33.3333% 33.33333% 33.3333%;
}

.grid-three-col-approve-previous-fm {
  grid-template-columns: 20% 1fr 1fr;
}

.grid-gap-1 {
  grid-gap: 10px;
}

@media (max-width: 40rem) {
  .grid-select-and-button,
  .grid-fy-and-payperiod-select {
    grid-template-columns: 1fr;
  }
  .grid-button-list {
    grid-gap: 0.5rem;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
  }
  .grid-filter-two-col {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 60rem) {
  .grid-three-col-projects {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 75rem) {
  .grid-three-col-edit-user,
  .grid-three-col-approve-previous-fm {
    grid-template-columns: 1fr;
  }
}

.flex {
  display: flex;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.full-width {
  width: 100%;
}

.wordwrap {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}

.button-wrap {
  white-space: normal;
  word-wrap: break-word;
}

.monospaced {
  font-family: 'Lucida Console', Monaco, monospace;
  font-size: 1rem !important;
}

.bold {
  font-weight: bold;
}

.float-right {
  float: right;
}

.display-none {
  opacity: 0;
}

.display-initial {
  opacity: 1;
}

.collapsible-content {
  display: block;
  max-height: 0px;
  overflow: hidden;

  transition: max-height 0.3s ease-in-out;
}

.collapsible-content.expanded {
  max-height: 100rem;
}

.block {
  display: block !important;
}

.min-width-200 {
  min-width: 200px;
}

.min-width-300 {
  min-width: 300px;
}

.styled-link {
  color: var(--accents-7);
}

.styled-link:hover {
  text-decoration: underline;
}

.full-page-grid {
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(1rem, 3.75rem)) 1fr;
  grid-gap: 1.25rem;
}

.full-page-grid-item-center {
  grid-column: 3 / span 10;
}

.full-page-grid-item-full {
  grid-column: 2 / span 12;
}

.full-page-grid-item-all {
  grid-column: 1 / -1;
}

@media (max-width: 30rem) {
  .full-page-grid {
    grid-template-columns: 1rem repeat(12, minmax(1rem, 3.75rem)) 1rem;
    grid-gap: 0.25rem;
  }

  .full-page-grid-item-center {
    grid-column: 4 / span 8;
  }
}
