
.projects {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 150px));
  justify-content: center;
  padding-bottom: 10px;
  backface-visibility: hidden;

  a {
    text-decoration: none;
    text-align: center;
    border-bottom: 1px solid var(--timesheetMenuTileBorderColor);
    padding: 2rem;
    color: var(--app-foreground);
    transition: all 0.2s ease-in-out;
    p {
      color: var(--tileTitleColor);
    }
    img {
      max-width: 100%;
      padding-bottom: 10px;
      filter: invert(50%);
    }
    &:hover {
      transform: scale(1.1);
      border-bottom-color: var(--timesheetMenuTileBorderColorInverse);
    }
  }
}

@media (min-width: 40rem) and (max-width: 60rem) {
  .projects {
    grid-template-columns: repeat(auto-fit, minmax(50px, 100px));
    grid-gap: 3em;
    a {
      padding: 1rem;
    }
  }
}

@media (max-width: 40rem) {
  .projects {
    grid-template-columns: repeat(auto-fit, minmax(50px, 100px));
    grid-gap: 2em;
    a {
      padding: 1rem;
    }
  }
}
