.inline-label-component-wrapper {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: initial;
  flex-direction: row;
  &.right {
    flex-direction: row-reverse;

    .label-span {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 5px;
      border-bottom: 1px solid var(--accents-2);
      border-left: 0;
      border-right: 1px solid var(--accents-2);
      border-top-left-radius: 0;
      border-top-right-radius: 5px;
      border-top: 1px solid var(--accents-2);
    }

    .inline-label-input-wrapper {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 0;
      border-top-left-radius: 5px;
      border-top-right-radius: 0;
    }
  }
}

.input-outer-wrapper {
  -moz-box-align: center;
  align-items: center;
  border-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid var(--accents-2);
  display: inline-flex;
  height: 37px;
  position: relative;
  transition: border 0.2s ease 0s, color 0.2s ease 0s;
  vertical-align: middle;
  width: initial;
  background: var(--app-background);

  &.right {
    flex-direction: row-reverse;
  }

  &:focus-within {
    border: 1px solid var(--accents-5);
  }

  &.disabled {
    cursor: not-allowed;
    background: var(--accents-1);
  }

  &.errored {
    border-color: var(--danger-red);
    input {
      color: var(--danger-red) !important;
    }
  }
}

.input-label {
  display: inline-block;
  font-weight: 600;
  padding: 0 8px 8px 0;
  font-size: 16px;
  line-height: 24px;
  color: #666;
}

.input-inner-wrapper {
  display: block;
  position: relative;
  width: 100%;
  margin: 4px 10px;

  &.with-icon {
    margin-right: 0;
  }
}

.icon-span {
  webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 0 10px;
  vertical-align: middle;
}

.label-span {
  background-color: var(--app-background);
  border-bottom-left-radius: 5px;
  border-bottom: 1px solid var(--accents-2);
  border-left: 1px solid var(--accents-2);
  border-top-left-radius: 5px;
  border-top: 1px solid var(--accents-2);
  color: rgb(155, 155, 155);
  font-size: 14px;
  line-height: 1;
  margin: 0;
  padding: 10px;
  pointer-events: none;
  width: initial;
}

.error-span {
  color: var(--danger-red);
  padding: 10px;
  pointer-events: none;
  margin: 0;
  font-size: 14px;
}

.inline-label-input-wrapper {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

input[type='text'],
input[type='number'],
input[type='email'],
input[type='password'] {
  border-radius: 0px;
  border: medium none;
  box-shadow: none;
  box-sizing: border-box;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  line-height: 27px;
  outline: currentcolor none 0px;
  width: 100%;
  color: var(--app-foreground);
  background-color: transparent;
  caret-color: var(--app-foreground);

  &::placeholder {
    color: var(--accents-5);
  }

  &:focus {
    outline-offset: -2px;
  }

  &:disabled {
    color: rgb(153, 153, 153);
    cursor: not-allowed;
    border-radius: 5px;
  }
}

.textarea-wrapper {
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  vertical-align: middle;
  // width: 50%;
  border: 1px solid var(--accents-2);
  border-radius: 5px;
  transition: border 0.2s ease 0s, color 0.2s ease 0s;
  margin-bottom: 10px;

  &:focus-within {
    border-color: rgb(136, 136, 136);;
  }

  &.errored {
    textarea {
      color: #ff001f;
    }
  }
}

textarea {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: initial;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  -webkit-appearance: textarea;
  // background-color: white;
  -webkit-rtl-ordering: logical;
  flex-direction: column;
  resize: auto;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  margin: 0em;
  font: 400 11px system-ui;
  border-width: 1px;
  border-style: solid;
  border-color: initial;
  border-image: initial;
  padding: 2px;

  background-color: transparent;
  box-shadow: none;
  box-sizing: border-box;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  line-height: 1.7;
  height: 100%;
  min-height: 100px;
  resize: none;
  width: 100%;
  color: var(--app-foreground);
  border-radius: 0px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  padding: 10px;
  caret-color: var(--app-foreground);
  
  &:disabled {
    background: var(--accents-1);
    cursor: not-allowed;
    border-radius: 5px;
  }
}
