@import './settings.scss';
@import './loader.scss';
@import './navbar.scss';
@import './loginPage.scss';
@import './homePage.scss';
@import './timesheetMenu.scss';
@import './contact.scss';
@import './notifications.scss';
@import './snackbars.scss';
@import './utilities.css';
@import './animations.scss';
@import './markdown.scss';
@import './buttons.scss';
@import './switches.scss';
@import './checkboxes.scss';
@import './radio.scss';
@import './textInputs.scss';
@import './collapse.scss';
@import "./stepper.scss";
@import './tables.scss';
@import './select.scss';
@import './darkModeToggle.scss';
@import './modal.scss';
@import './RichTextEditor.scss';
@import './datePickerOverrides.scss';
@import './normalize.css';

// @custom-media --xsmall (width >= 30rem); /* 480px */
// @custom-media --small (width >= 40rem); /* 640px */
// @custom-media --medium (width >= 60rem); /* 960px */
// @custom-media --large (width >= 75rem); /* 1200px */
// @custom-media --xlarge (width >= 87.5rem); /* 1400px */
// @custom-media --xxlarge (width >= 110rem); /* 1600px */
// @custom-media --xxxlarge (width >= 120rem); /* 1920px */

html {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: $body_font;
  -webkit-transition: ease-in-out 200ms !important;
  -moz-transition: ease-in-out 200ms !important;
  -ms-transition: ease-in-out 200ms !important;
  -o-transition: ease-in-out 200ms !important;
  transition: ease-in-out 200ms !important;
  transition-delay: 0 !important;
  background: var(--app-background);
  color: var(--app-foreground);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  padding-top: 60px;
  padding: 2rem;
  font-family: $body_font;
  min-height: 100vh;
}

a { text-decoration: none; }

h1, h2, h3, h4, h5, p, div {
  color: var(--app-foreground);
}

.heading-1 { font-size: 3rem; } 
.heading-2 { font-size: 2.25rem; }
.heading-3 { font-size: 1.5rem; }
.heading-4 { font-size: 1.25rem; }
.heading-5 { font-size: 1rem; }
.heading-6 { font-size: .875rem; }

.i {         font-style: italic; }
.b {         font-weight: bold; }
.underline { text-decoration: underline; }
.strike {    text-decoration: line-through; }
.ttc {       text-transform: capitalize; }
.ttu {       text-transform: uppercase; }

body.light-mode {
  --accents-1: #fafafa;
  --accents-2: #eaeaea;
  --accents-3: #999999;
  --accents-4: #888888;
  --accents-5: #666666;
  --accents-6: #444444;
  --accents-7: #333333;
  --accents-8: #111111;

  --app-background: white;
  --app-foreground: black;

  // Background Stuff
  --loaderColor: var(--evalblack);

  // Navigation Colors
  --navLinkActiveColor: var(--light-orange);
  --navLinkInactiveColor: var(--x-black);
  --navLinkHoverColor: darkgrey;

  // Timesheet Menu
  --tileTitleColor: var(--x-slateblack-700);
  --timesheetMenuTileBorderColor: var(--callbackpink);
  --timesheetMenuTileBorderColorInverse: var(--x-black);
}

body.dark-mode {
  --accents-8: #fafafa;
  --accents-7: #eaeaea;
  --accents-6: #999999;
  --accents-5: #888888;
  --accents-4: #666666;
  --accents-3: #444444;
  --accents-2: #333333;
  --accents-1: #111111;

  --app-background: black;
  --app-foreground: #c8c5bf;

  // Background stuff
  --loaderColor: white;

  // Navigation Colors
  --navLinkActiveColor: var(--dark-orange);
  --navLinkInactiveColor: white;
  --navLinkHoverColor: rgba(239, 131, 84, 1);

  // Timesheet Menu
  --tileTitleColor: var(--x-salmon-100);
  --timesheetMenuTileBorderColor: var(--callbackpink);
  --timesheetMenuTileBorderColorInverse: white;
}


.metrics {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr;
  .element {
    border: 1px solid white;
  }
}

.pulse {
  animation-name: pulse_animation;
  animation-duration: 5000ms;
  transform-origin: 70% 70%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
