.format-toolbar {
  display: flex;
  border-bottom: solid 1px;
  padding: 10px 0;
  margin: 0 0 10px 0;
}

.tooltip-icon-button {
  border: 0;
}

.editor-menu {
  padding-left: 15px;
  background: gray;
}

.editor-menu-svg {
  cursor: pointer;
  padding-right: 10px;
  fill: #ccc;
  &::hover {
    opacity: 0.7;
  }
  &.active {
    fill: black;
  }

};