.stepper {
  display: flex;
  align-items: center;

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
      padding-right: 20px;

    &:last-of-type {
      padding-left: 20px;
    }

    svg {
      // width: 20px;
      max-width: 40px;
      padding-bottom: 10px;
      fill: var(--accents-2);
      text {
        fill: var(--accents-5);
      }
    }

    span {

    }

    &.active {
      svg {
        fill: var(--lightblue);
        text {
          fill: white;
        }
      }
    }
  }

  .step-connector {
    flex: 1 1 auto;
    span {
      display: block;
      border: 1px solid var(--accents-2);
    }
  }
}