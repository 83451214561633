.notification {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1vw 0;
  box-shadow: 0px 1px 5px 0px var(--app-foreground), 0px 2px 2px 0px var(--app-foreground),
    0px 3px 1px -2px var(--app-foreground);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
  }

  &.info {
    &::before {
      background-color: var(--lightblue);
    }
  }
  &.warning {
    &::before {
      background-color: var(--light-yellow);
    }
  }
  &.danger {
    &::before {
      background-color: var(--danger-red);
    }
  }
  &.success {
    &::before {
      background-color: var(--success-green);
    }
  }

  div {
    padding-right: 20px;
  }

  .notification-icon {
    padding-left: 20px;

    svg {
      width: 2rem;
    }
    // display: flex;
    // TODO: Figure out how to make this work
    &.success {
      svg {
        stroke: var(--success-green);
        color: var(--success-green);
      }
    }
  }

  .notification-close {
    display: flex;
    svg {
      width: 1rem;
      padding: 10px;
      &:hover {
        background-color: lightgray;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }

  .notification-action {
    a {
      color: var(--app-foreground);
      text-decoration: underline;
    }
  }
}

body.dark-mode {
  .notification {
    &.info {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background-color: var(--x-white);
      }
    }

    .notification-icon,
    .notification-close {
      svg {
        fill: white;
        fill-opacity: 0.9;
        &:hover {
          fill: black;
        }
      }
    }
  }
}

@media screen and (max-width: 40rem) {
  .notification {
    flex-wrap: wrap;
    .notification-icon,
    .notification-message,
    .notification-action {
      padding-bottom: 10px;
      padding-left: 8px;
      width: 100%;
      text-align: center;
      align-content: center;
      justify-content: center;
    }
  }
}
