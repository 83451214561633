@keyframes slideInFromBottom {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.image-container {
  grid-area: imagearea;
  text-align: center;
  img {
    max-width: 80%;
  }
}

.text-container {
  color: var(--app-foreground);
}

@media (max-width: 700px) {
  .hero-container {
    display: grid;
    grid-template-areas:
      'imagearea'
      'textarea';
  }
  .text-container {
    text-align: center;
  }
}

@media (min-width: 700px) {
  .hero-container {
    display: grid;
    grid-template-columns: 40% 60%;
    // grid-template-rows: 10vw 30vw;
    grid-template-areas: 'imagearea textarea';
  }
}

.products {
  display: grid;
  grid-gap: 5em;
  @media (min-width: 960px) {
    grid-gap: 10em;
  }
  grid-template-columns: repeat(auto-fit, 230px);
  justify-content: center;
  .product-logo {
    text-align: center;
    width: 100%;
    height: 200px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    padding-bottom: 10px;
  }
  .product {
    display: grid;
    grid-template-areas:
      'product-logo'
      'product-link'
      'product-point'
      'product-seconday-action'
      'product-primary-action';
    h2 {
      text-align: center;

      font-size: 1.5em;
      font-weight: 400;
      margin: 0;
      padding: 0.5em 0;
      a {
        text-decoration: none;
        color: var(--tileTitleColor);
      }
    }
    p {
      color: var(--app-foreground);
      text-align: center;
    }
  }
}
