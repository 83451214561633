table {
  max-width: 100%;
  max-height: 100%;
  border-collapse: collapse;
  box-shadow: 0px 1px 5px 0px var(--app-foreground), 0px 2px 2px 0px var(--app-foreground),
    0px 3px 1px -2px var(--app-foreground);

  td, th {
    padding: 12px 20px;
  }
  thead {
    th {
      text-align: left;
      border-bottom: 3px solid var(--evalblack);
    }
  }
  tbody {
    tr {
      td > input {
        width: 2rem;
      }
    }
  }

  tbody, tfoot {
    tr {
      .row-expander-plus, .mobile-total {
        display: none;
      }
    }
  }
}

@media (max-width: 60rem) {
  table {
    td, th {
      padding: 4px 8px;
      input {
        width: 1.3rem;
      }
    }
    
  }
}

// Timesheet table specific general styles
.timesheet-table {
  thead {
    th {
      border-color: var(--success-green);
    }
  }
  &.too-many-hours, &.too-few-hours {
    thead {
      th {
        border-color: var(--danger-red);
      }
    }
  }
  tbody {
    tr {
      td { 
        .timesheet-input {
          max-width: 4rem;
        }
      }
    }
  }
}

// Make the table hoverable if hoverable class is present
table {
  tbody {
    .selectable:hover {
      cursor: pointer;
    }
    .hoverable:hover {
      background-color: var(--accents-2);
    }
    .has-error {
        color: var(--danger-red);
    }
  }
}

// Styles specifically for Timesheet Table on smaller devices
/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media  (max-width: 46rem) {

  /* Force table to not be like tables anymore */
  .timesheet-table {
    display: block;

    thead, tbody, tfoot, th, td, tr { 
      display: block; 
    }
    
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr { 
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    
  
      tbody, tfoot {
        tr {
          td:not(:first-child) {
            display: none;
          }
          &:hover {
            color: var(--accents-5);
          }
          .row-expander-plus, .mobile-total {
            display: inline-block;
          }
          // If the class is mobile-expanded, display the td's
          &.mobile-expanded {
            td:nth-of-type(n+2) { 
              display: block; 
              -webkit-animation: fadeIn 1s;
              animation: fadeIn 1s;
            }
            }
          }
        }
  
        td { 
          /* Behave  like a "row" */
          border: none;
          border-bottom: 1px solid var(--accents-4); 
          position: relative;
          padding-left: 80%; 
        }
      
        td:before { 
          /* Now like a table header */
          position: absolute;
          /* Top/left values mimic padding */
          top: 6px;
          left: 10px;
          width: 45%; 
          height: 100%;
          // padding-right: 10px; 
          white-space: nowrap;
          text-align: left;
        }
        
        td:nth-of-type(1) { 
          font-weight: bold; 
          padding: 10px; 
          display: flex;
          -moz-box-align: center;
          align-items: center;
          -moz-box-pack: justify;
          justify-content: space-between;
          font-weight: 600;
          text-transform: none;
          cursor: pointer;
          outline-width: 0px;
        }
        /*
        Label the data
        */
        td:nth-of-type(2):before { content: "Monday"; }
        td:nth-of-type(3):before { content: "Tuesday"; }
        td:nth-of-type(4):before { content: "Wednesday"; }
        td:nth-of-type(5):before { content: "Thursday"; }
        td:nth-of-type(6):before { content: "Friday"; }
        td:nth-of-type(7):before { content: "Saturday"; }
        td:nth-of-type(8):before { content: "Sunday"; }
        td:nth-of-type(9):before { content: "Charged To"; }
        td:nth-of-type(10):before { content: "Total"; }
  }
	
}