.modal {
  position: fixed;
  height: 0vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.25s;
  z-index: 9999;
  overflow: hidden;

  &.open {
    position: fixed;
    width: 100%;
    min-height: 100%;
    > .content-wrapper {
      transform: scale(1);
    }
  }

  .content-wrapper {
    position: relative;
    display: grid;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;

    grid-template-rows: 10% 70% 10%;
    grid-gap: 2rem;
    justify-self: center;
    padding: 2.5rem;
    width: 50%;
    background-color: var(--app-background);
    border-radius: 1rem;
    box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.5);

    transform: scale(0);
    transition: transform 0.25s;
    transition-delay: 0.15s;
    will-change: transform;

    .modal-header {
      padding-bottom: 10px;
      border-bottom: 1px solid var(--x-salmon-100);
      max-width: 100%;
    }
  }
}

@media (max-width: 40rem) {
  .modal {
    .content-wrapper {
      width: unset;
    }
  }
}
